<template>
    <div class="action-main">
        <div class="status-wrapper">
            <div class="button-wrapper" v-for="item in $urlConstant.markStatus" :key="item.value">
                <el-button class="status-button" :class="reqMark.status === item.value?'status-button-selected':''"
                           @click="statusChange(item)">{{item.name}}</el-button>
                <div class="status-dot">
                    <div :style="'background-color:' + item.color"></div>
                </div>
            </div>
        </div>
        <div>
            <el-input class="search-input" placeholder="病历号" v-model="reqMark.no" @input="searchChange">
                <template slot="append">
                    <img class="search-icon" src="@/assets/image/magnifier.png">
                </template>
            </el-input>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ActionBar",
        props: {
            searchQuery: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                reqMark:{}
            }
        },
        created() {
            this.reqMark = JSON.parse(JSON.stringify(this.searchQuery))
        },
        methods:{
            statusChange(item) {
                this.$set(this.reqMark,'status',item.value)
                this.searchChange()
            },
            searchChange() {
                this.$emit("searchChanged",this.reqMark)
            }
        }
    }
</script>

<style scoped>
.action-main {
    height: 76px;
    /*width: 100vw;*/
    background-color: #272727;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
    .status-wrapper{
        display: flex;
    }
    .button-wrapper{
        height: 24px;
        margin: 0 20px;
    }
    .status-button {
        border: 1px solid #DEDEDE;
        border-radius: 29px;
        width: 90px;
        height: 24px;
        line-height: 0px;
        background-color: #272727;
        font-family: SourceHanSansCN-Regular;
        font-size: 12px;
        color: #FEFEFE;
        letter-spacing: 1px;
        text-align: center;
    }
    .status-button-selected{
        background: #54B8C7;
        border: 1px solid #272727;
    }
    .status-dot{
        width: 100%;
        height: 7px;
        display: flex;
        margin-top: 8px;
        justify-content: center;
    }
    .status-dot div{
        height: 7px;
        width: 7px;
        border-radius: 3.5px;
    }
    .search-input {
        background: #757575;
        box-shadow: 0 1px 0 0 rgba(87,87,87,0.50);
        border-radius: 29px;
        width: 242px;
        height: 35px;
    }
    .search-input >>> .el-input__inner {
        background-color: transparent;
        border: unset;
        height: 35px;
        color: white;
    }
    .search-input >>> .el-input-group__append {
        background-color: transparent;
        border: unset;
    }
    .search-icon {
        height: 22px;
        width: 22px;
        margin-top: 7px;
    }
</style>
