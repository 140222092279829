<template>
    <div class="header">
        <div class="title-bar-title">
            {{title}}
        </div>
        <div class="title-bar-menu">
            <logout-header active-selected="logout"></logout-header>
        </div>
    </div>
</template>

<script>
    import logoutHeader from "./logoutHeader";

    export default {
        name: "XyHeader",
        components: {
            logoutHeader
        },
        props: {
            title: {
                type: String,
                required: false,
                default: ''
            }
        }
    }
</script>

<style scoped>
    .header {
        height: 40px;
        /*width: 100vw;*/
        background-color: black;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
        font-family: SourceHanSansCN-Medium;
        font-size: 12px;
        color: #FFFFFF;
        letter-spacing: 0.89px;
        text-align: center;
        line-height: 40px;
        display: flex;
        justify-content: space-between;
    }

    .title-bar-title {
        width: calc(100vw - 100px);
        /*padding-left: 100px;*/
    }

    .title-bar-menu {
        display: flex;
        align-items: center;
    }

    .title-bar-menu >>> .el-menu {
        height: 40px;
        background: #38B0B4;
    }

    .title-bar-menu >>> .el-submenu__title {
        border: unset !important;
    }
</style>
