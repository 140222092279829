<template>
    <div class="mark_list">
        <XyHeader title="湘雅AI辅助诊断录入系统"></XyHeader>
        <ActionBar :search-query="this.searchQuery" @searchChanged="searchChange"></ActionBar>
        <el-table :data="markList" stripe class="table-main" header-cell-class-name="mark-table-header-cell"
        cell-class-name="mark-table-header-cell" header-row-class-name="mark-table-header-row"
        row-class-name="mark-table-cell-row">
            <el-table-column
                    prop="no"
                    label="病历号">
            </el-table-column>
<!--            <el-table-column-->
<!--                    prop="name"-->
<!--                    label="姓名">-->
<!--            </el-table-column>-->
            <el-table-column
                    prop="date"
                    label="日期">
            </el-table-column>
            <el-table-column
                    prop="status"
                    label="状态">
                <template slot-scope="scope">
                    <div class="status-dot"><div :style="'background-color:' + findStatusColor(scope.row.status)"></div></div>
                </template>
            </el-table-column>
            <el-table-column
                    label="操作">
                <template slot-scope="scope">
                    <el-button icon="edit-img" @click="doEdit(scope.row)" type="text" style="font-size: 20px"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align: right;">
            <el-pagination
                    class="mark-pagination"
                    layout="total,prev,pager,next"
                    :total="total"
                    :page-size="$urlConstant.pageSize"
                    :current-page="currentPage"
                    @current-change="getData">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import {
        // mapActions,
        mapState,
        mapMutations,
        // mapGetters
    } from 'vuex'
    import XyHeader from "../common/XyHeader";
    import ActionBar from "./components/ActionBar";
    export default {
        name: "MarkList",
        components: {ActionBar, XyHeader},
        data() {
            return {
                markList: [],
                totalPages: 0,
                total: 0,
                currentPage: 1,
                reqMark: {
                }
            }
        },
        computed: {
            ...mapState('mark',[
                'searchQuery'
            ])
        },
        created: function () {
            // 组件创建完后获取数据，
            // 此时 data 已经被 observed 了
            console.log('created')
            this.reqMark = JSON.parse(JSON.stringify(this.searchQuery))
            this.currentPage = this.reqMark.currentPage
            this.getData(this.currentPage)
        },
        methods: {
            ...mapMutations('mark',[
                'storeSearchQuery',
                'cleanSearchQuery'
            ]),
            getData(index) {
                this.reqMark.currentPage = index
                this.storeSearchQuery(this.reqMark)

                this.$httpUtil.post(this.$urlConstant.xiangya.listPost + 'page/' + (index - 1)
                    + '/size/' + this.$urlConstant.pageSize, this.reqMark, (responseData) => {
                    this.markList = responseData.data.content
                    this.total = responseData.data.totalElements;
                    this.totalPages = responseData.data.totalPages;
                    this.currentPage = index;
                }, this)
            },
            doEdit(mark) {
                this.$router.push({name: 'MarkEdit', query: {'id': mark.id}})
            },
            searchChange(reqMark) {
                this.reqMark = reqMark
                this.getData(1)
            },
            findStatusName(typeValue) {
                for (const index in this.$urlConstant.markStatus) {
                    const type = this.$urlConstant.markStatus[index]
                    if (type.value == typeValue) {
                        return type.name
                    }
                }
                return typeValue
            },
            findStatusColor(typeValue) {
                for (const index in this.$urlConstant.markStatus) {
                    const type = this.$urlConstant.markStatus[index]
                    if (type.value == typeValue) {
                        return type.color
                    }
                }
                return typeValue
            },
        }
    }
</script>

<style scoped>
    .mark_list{
        height: 100vh;
        background-color: #272727;
    }
    .table-main {
        background-color: #0A0A0A;
    }
    .table-main >>> .el-table__header {
        background-color: #0A0A0A;
    }
    .table-main:before {
        background-color: #272727;
    }
    .status-dot{
        width: 100%;
        height: 10px;
        display: flex;
        margin-top: 8px;
        justify-content: flex-start;
    }
    .status-dot div{
        height: 10px;
        width: 10px;
        border-radius: 5px;
    }
    .mark-pagination >>> .el-pagination__total{
        color: #FFFFFF;
    }
    .mark-pagination >>> button {
        background-color: #272727;
    }
    .mark-pagination >>> .number , .mark-pagination >>> .el-icon-more{
        background-color: #272727;
        color: #FFFFFF;
    }
    .mark-pagination >>> .active {
        color: teal;
    }
    .mark-pagination {
        background-color: #000000;
    }
</style>
